<template>
  <div>
    <b-overlay
      show
      spinner-variant="primary"
      spinner-type="grow"
      spinner-large
      rounded="sm"
      style="top: 25em;"
      v-if="overlayStatus"
    >
    </b-overlay>
    <!-- BTAB Clientes -->
    <div style="width: 100%;">
      <b-tabs
        horizontal
        content-class="col-12 col-md-12 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-12 col-12"
        nav-class="nav-left"
      >
        <!-- Client tab -->
        <b-tab v-for="buyer in buyers" :key="buyer.idCompany">
          <!-- title -->
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">{{buyer.companyName}}</span>
          </template>
          <b-card class="table-responsive">

            <!-- Swtich -->
            <div class="demo-inline-spacing" v-if="false">
              <div style="display: contents;">
                <b-card-text class="mb-0 switch">
                  Activo
                </b-card-text>
                <b-form-checkbox
                  checked="true"
                  class="custom-control-success switch"
                  name="check-button"
                  switch
                  @change="toggleUser(buyer.idCompany, $event)"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
            </div>

            <!-- Table -->
            <table class="table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Precio anterior</th>
                  <th>% anterior</th>
                  <th>Precio nuevo</th>
                  <th>% nuevo</th>
                  <th>Comisión</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in buyer.items" :key="index">
                  <!-- Nombre -->
                  <td>
                    <span>{{item.alias}}</span>
                  </td>
                  <!-- Precio Anterior -->
                  <td>
                    <span>{{setPastDataClient(buyer.idCompany,item.idItem, "price")}}</span>&nbsp;
                    <feather-icon
                      :icon="getTrendIcon(buyer.idCompany, item.idItem, 'icon')"
                      :class="getTrendIcon(buyer.idCompany, item.idItem, 'color')"
                      size="16"/>
                  </td>
                  <!-- % Anterior -->
                  <td> 
                    <span>{{setPastDataClient(buyer.idCompany,item.idItem, "percentage")}}</span>
                  </td>
                  <!-- Precio Nuevo -->
                  <td>
                    <b-form-input
                        id="input"
                        @keypress="restrictNumber($event)"
                        @input="updateInput(buyer.idCompany, item.idItem, $event, 'price')"
                        :value="updateValue(buyer.idCompany, item.idItem, 'price')"
                        autocomplete="off"
                    />
                  </td>
                  <!-- % Nuevo-->
                  <td>
                    <b-form-input
                        id="input"
                        @keypress="restrictNumber($event)"
                        @input="updateInput(buyer.idCompany, item.idItem, $event, 'percentage')"
                        :value="updateValue(buyer.idCompany, item.idItem, 'percentage')"
                        autocomplete="off"
                    />
                  </td>
                  <!-- Comisión -->
                  <td>
                    <b-form-input
                        id="input"
                        @keypress="restrictNumber($event)"
                        @input="updateInput(buyer.idCompany, item.idItem, $event, 'extraComissionPrice')"
                        autocomplete="off"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
             <table class="table">
               <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <!-- <b-button
                      @click="saveData()">
                      Guardar
                    </b-button> -->
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
            </table>
          </b-card>
        </b-tab>
        <!--/ Client tab -->

      </b-tabs>
    </div>
    <!-- TODO CARLOS -->
    <div class="mb-5" style="text-align: center;" v-if="!overlayStatus">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="generatePrices()"
      >
        <feather-icon
          icon="RepeatIcon"
          class="mr-50"
        >
        </feather-icon>
        Generar precios
      </b-button>
    </div>
    <!-- BTAB Proveedores -->
    <div v-if="showButtons">
      <b-tabs
        horizontal
        content-class="col-12 col-md-12 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-12 col-12"
        nav-class="nav-left"
      >
        <!-- Supplier tab -->
        <b-tab v-for="seller in sellers" :key="seller.idCompany">
          <!-- title -->
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">{{seller.companyName}}</span>
          </template>
          <b-card class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th style="width: 23%;">Precio seleccionado</th>
                  <th>Precio anterior</th>
                  <th v-for="envio in envios" :key="envio">
                    Envío {{envio}}
                  </th>
                  <!-- <th>PR MAX GD</th>
                  <th>PR MAX PICK</th> -->
                  <th>Ajuste 1 (-)</th>
                  <th>Ajuste 2 (+)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in seller.items" :key="index">
                  <!-- Nombre -->
                  <td>
                    <span>{{item.alias}}</span>
                  </td>
                  <td style="width: 23%;">
                    <!-- TODO CARLOS -->
                    <b-form-select
                      style="width:100%"
                      size="lg"
                      :options="metalsList[item.idItem]"
                      :value="getSelectedPrices(seller.idCompany, item.idItem)"
                      v-on:change="newSelectedPrices($event, item.idItem, seller.idCompany)"
                    />
                  </td>
                  <!-- Precio Anterior -->
                  <td>
                    <span>{{setPastDataSupplier(seller.idCompany,item.idItem, "price")}}</span>
                  </td>
                  <!-- Envio -->
                  <td v-for="envio in envios" :key="envio">
                    <!-- TODO CARLOS -->
                    <span>{{getBuyingPrices(seller.idCompany, envio, item.idItem)}}</span>
                  </td>
                  <!-- Ajuste -->
                  <td>
                    <!-- TODO CARLOS -->
                    <b-form-input
                        id="input"
                        @keypress="restrictNumber($event)"
                        @input="updateInput(seller.idCompany, item.idItem, $event, 'adjustment1')"
                        :value="getComissionsPrices(seller.idCompany, item.idItem, 1)"
                        autocomplete="off"
                    />
                  </td>
                  <!-- Ajuste 2 -->
                  <td>
                    <!-- TODO CARLOS -->
                    <b-form-input
                        id="input"
                        @keypress="restrictNumber($event)"
                        @input="updateInput(seller.idCompany, item.idItem, 
                        $event, 'adjustment2')"
                        :value="getComissionsPrices(seller.idCompany, item.idItem, 2)"
                        autocomplete="off"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table">
              <tr>
                <td></td>
                <td></td>
                <td v-for="envio in envios" :key="envio">
                  <b-button
                    v-b-tooltip.click.v-dark
                    class="mt-1"
                    title="¡Texto copiado!"
                    variant="dark"
                    v-if="showButtons"
                    @click="copyText(seller.idCompany, envio, 'exp')"
                  >
                    <feather-icon
                      icon="ClipboardIcon"
                      class="mr-50"
                    />
                    Copiar
                  </b-button>
                </td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </b-card>
        </b-tab>
        <div style="text-align: center;">
          <b-row align-h="center">
            <b-col
              lg="3"
              sm="12"
              class="mb-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                @click="messageSellers()"
                :disabled="smsStatus || smsSucess"
              >
                <div v-if="smsSucess">
                  <feather-icon
                    icon="MessageSquareIcon"
                    class="mr-50"
                  />
                  SMS Enviado/s a proveedor/es
                </div>
                <div v-else-if="smsStatus">
                  <b-spinner small/>
                  &nbsp; Enviando SMS...
                </div>
                <div v-else>
                  <feather-icon
                    icon="MessageSquareIcon"
                    class="mr-50"
                  />
                  Enviar SMS ({{ sellers.length }} proveedor/es)
                </div>
              </b-button>
            </b-col>
            <b-col
              lg="3"
              sm="12"
            >
              <b-button
                v-if="!saveButtonStatus && showButtons"
                @click="confirmText()"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                <span class="align-middle">{{ saveButtonText }}</span>
              </b-button>
              <div class="spinner-border" role="status" v-if="saveButtonStatus">
                <span class="sr-only">Cargando...</span>
              </div>
            </b-col>
          </b-row>
        </div>
        <!--/ Supplier tab -->

      </b-tabs>
    </div>
  </div>
</template>

<style scoped src="./PricesAdd.css">
</style>


<script>
import { BTabs, BTab, BButton, BForm, BFormGroup, BSpinner,
BFormInput, BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BInputGroup, BInputGroupAppend, BFormSelect,
BFormCheckbox, VBTooltip, BAlert, BOverlay } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { forEach } from 'postcss-rtl/lib/affected-props'

import utils from '../../../utils/utils.js';



export default {
  components: {
    BAlert,
    BTabs,
    BTab,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BOverlay,
    BSpinner,
    // <!-- TODO CARLOS-->
    BFormSelect
    // <!-- TODO CARLOS-->
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {

// <!-- TODO CARLOS-->
      metalsList: [],

      options: {},
      envios: 1,
      nuevosPrecios: 0,
      envioData: 0,
      preciosClientes: [],
      preciosProveedores: [],
      logisticPrice: [
        {
          idCompany: 1,
          value: 1,
        },
        {
          idCompany: 2,
          value: 2,
        },
        {
          idCompany: 3,
          value: 1,
        },
      ],
      impact: [],
      data: [],
      data2: [],
      buyers: [],
      buyers: [],
      sellers: [],
      buyersCount: 0,
      sellersCount: 0,
      arrayMetales: [],
      stockPrice: [],
      idQuote: null,
      saveButtonText: "Crear nueva cotización",
      saveButtonStatus: false,
      showButtons: false,
      pastPreciosClientes: [],
      pastPreciosProveedores: [],

      // Props
      overlayStatus: true,
      smsStatus: false,
      smsSucess: false,
    }
  },
  created() {
    this.getStockPrices();
    this.getLastQuote();
    this.getData();
  },
  beforeRouteLeave (to, from , next) {  // Evitar que se salgan de la pantalla y perder datos
    const answer = window.confirm('¿Seguro que desea salir? Los cambios no guardados se perderán.');
    if (answer) {
      next()
    } else {
      next(false)
    }
  },
  methods: {
    getLastQuote() {
      this.$axios.get(this.$services + 'quotes/get_quotes', {
        params: {
          operation: "3",
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          if (res.data.data.length){
            this.getPastQuoteData(res.data.data[0].idQuote);
          }
        }
      }).catch(error => this.$verifyToken(error.response.data.data));
    },
    getPastQuoteData(idQuote) {
      this.$axios.get(this.$services + 'quotes/get_data_quote',  {
        params: {
          operation: "2",
          idQuote: idQuote,
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          // Get data
          let buyers = res.data.data.buyers;
          let sellers = res.data.data.sellers;

          // let pastPreciosClientes = [];

          // Recorre todos los clientes
          buyers.forEach(buyer => {
            // Recorre todos los metales
            buyer.items.forEach(item => {
              this.pastPreciosClientes.push({
                idCompany: buyer.idCompany,
                idItem: item.idItem,
                price: item.price,
                percentage: item.percentage,
                extraComissionPrice: item.extraComissionPrice,
                profit: item.profit,
                active: item.active,
                logisticPrice: buyer.logisticPrice,
                impact: item.impact,
                idStock: item.idStock,
                stockName: 'stocks'
              });
            });
          });

          sellers.forEach(seller => {
            // Recorre todos los metales
            seller.items.forEach(item => {
                this.pastPreciosProveedores.push({
                  idCompany: seller.idCompany,
                  idItem:  item.idItem,
                  shipment1: item.shipment1,
                  shipment2: item.shipment2,
                  adjustment1: item.adjustment1,
                  adjustment2: item.adjustment2,
                  logisticPrice: seller.logisticPrice,
                  itemName: item.alias,
                });
            });
          });

        }
        // console.log(res.data);
      }).catch(error => this.$verifyToken(error.response.data.data));
    },
    setPastDataClient(idCompany, idItem, type) {
 
      //TEMP
      let index = this.pastPreciosClientes.findIndex(company => 
        company.idCompany == idCompany &&
        company.idItem == idItem 
      );

      if (type == 'price') {
        if (index > -1 && this.pastPreciosClientes[index].price > 0)
          return "$"+this.pastPreciosClientes[index].price;
        else{
          return "N/A";
        }
      } else if (type == 'percentage') {
        if (index > -1 && this.pastPreciosClientes[index].percentage > 0)
          return this.pastPreciosClientes[index].percentage + "%";
        else{
          return "N/A";
        }
      }
    },
    setPastDataSupplier(idCompany, idItem, type) {
 
      //TEMP
      let index = this.pastPreciosProveedores.findIndex(company => 
        company.idCompany == idCompany &&
        company.idItem == idItem 
      );
      
      if (type == 'price') {
        if (index > -1 && this.pastPreciosProveedores[index].shipment1 > 0)
          return "$"+this.pastPreciosProveedores[index].shipment1;
        else{
          return "N/A";
        }
      }
    }, 
    getStockPrices() {
      this.$axios.get(this.$services + 'prices/get_Prices', {
        params: {
          operation: 1,
          idStatus: 2
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.stockPrice = res.data.data;
        }
        // console.log(res.data);
      }).catch(error => this.$verifyToken(error.response.data.data));
    },
    getData() {
      this.$axios.get(this.$services + 'quotes/get_template_quotes', {
        params: {
          operation: 1,
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          // Get data
          this.data2 = res.data.data;
          this.buyers = res.data.data.buyers;
          this.sellers = res.data.data.sellers;

          // Recorre todos los clientes
          this.buyers.forEach(buyer => {
            // Recorre todos los metales
            buyer.items.forEach(item => {
              this.preciosClientes.push({
                idCompany: buyer.idCompany,
                companyName: buyer.companyName,
                currency: buyer.currency,
                measurementUnit: buyer.measurementUnit,
                idItem: item.idItem,
                price: null,
                percentage: null,
                extraComissionPrice: null,
                active: true,
                logisticPrice: buyer.logisticPrice ? buyer.logisticPrice : 0,
                impact: item.impact ? item.impact : 1,
                profit: item.profit,
                profitAction: item.profitAction,
                idStock: item.idStock,
                stockName: 'stocks',
                comissions: item.comissions,
                timeseries: item.timeseries,
                random: Math.random(),
              });

              this.impact.push({
                idCompany: buyer.idCompany,
                idItem: item.idItem,
                active: true,
                value: item.impact
              });

            });
          });

          // console.log(this.preciosClientes)

          // Recorre todos los proveedores
          this.sellers.forEach(seller => {
            // Recorre todos los metales
            // console.log(seller.items)
            seller.items.forEach(item => {
              // for (let idShipping = 1; idShipping <= 2; idShipping++) {
                this.preciosProveedores.push({
                  idCompany: seller.idCompany,
                  currency: seller.currency,
                  measurementUnit: seller.measurementUnit,
                  idItem:  item.idItem,
                  shipment1: null,
                  shipment2: null,
                  adjustment1: null,
                  adjustment2: null,
                  logisticPrice: seller.logisticPrice ? seller.logisticPrice : 0,
                  itemName: item.alias,
                  comissions: item.comissions,
                  idSelectedCompany: null,
                  selectedPrice: null,
                  random: Math.random(),
                });
              // }
            });
          });
        }
        // console.log(this.preciosProveedores);
      }).catch(error => this.$verifyToken(error.response.data.data)).finally(() => {
        this.overlayStatus = false;
      });
    },
    toggleUser(idCompany, event) { // Activa o desactiva el company

      // Obetener los indices de los precios de un company
      let result = this.preciosClientes.reduce(function(acc, curr, i) {
        if (curr.idCompany === idCompany) {
          acc.push(i);
        }
        return acc;
      }, []);

      // Setea los activos de precios de ese company
      if (result.length > 0 ) {
        result.forEach(element => {
          this.preciosClientes[element].active = event;
        });
      }

      // Obtener los indices de impacto de un company
      let indexesImpact = this.impact.reduce(function(acc, curr, i) {
        if (curr.idCompany === idCompany) {
          acc.push(i);
        }
        return acc;
      }, []);

      // Desactivar los impacto de ese company
      if (indexesImpact.length > 0 ) {
        indexesImpact.forEach(element => {
          this.impact[element].active = event;
        });
      }



      // Obtener los nuevos impactos positivos
      let newImpacts = this.impact.filter(impact => impact.active == true);
      
      // Crea arreglo de todos los metales que se usan
      let arrayMetals = newImpacts.reduce(function(acc, curr, i) {
        // Checa si existe el metal en el arreglo
        if (acc.findIndex(element => element == curr.idItem) == -1) {
          acc.push(curr.idItem);
        }
        return acc;
      }, []);

      arrayMetals.forEach(idItem => {
        //Arreglo de un metal
        let arrayMetalImpact = newImpacts.filter(impact => impact.idItem == idItem);
        
        //Suma de los nuevos impactos
        let newTotal = arrayMetalImpact.reduce(function (acc, curr) {
          return acc + curr.value
        },0);

        //Nuevo total 
        newTotal = parseFloat(newTotal.toFixed(2));

        // Recorre cada company con ese metal y calcula el nuevo impacto
        arrayMetalImpact.forEach(element => {

          // Obtiene la posicion del company con ese metal
          let indexClientMetal = this.preciosClientes.findIndex(company => 
            company.idCompany == element.idCompany &&
            company.idItem == element.idItem 
          );

          // Calcula y setea el nuevo impacto
          // console.log(parseFloat((((element.value*100)/newTotal)/100).toFixed(2)))
          this.preciosClientes[indexClientMetal].impact = parseFloat((((element.value*100)/newTotal)/100).toFixed(2));
        });
      });
    },
    saveData() {
      // console.log(this.preciosProveedores)
      // return

      this.saveButtonStatus = true;
      if (this.idQuote != null) {
        this.updateClientPrices(this.idQuote);
        this.updateSupplierPrices(this.idQuote);
        this.createComissions(this.idQuote, 'client', 'update');
        this.createComissions(this.idQuote, 'supplier', 'update');
        this.showToast('success', '¡Cotización guardada con éxito!');
        this.saveButtonStatus = false;
      } else {
        this.createQuote();
      }

    },
    createQuote() {
      // Guardar Quote
      this.$axios.post(this.$services + 'quotes/create_quote', {
        idStatus: '2', // Activo
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          // console.log(res.data.data.idQuote)
          this.idQuote = res.data.data.idQuote;
          this.saveButtonText = "Guardar cambios";
          this.createClientPrices(this.idQuote);
          this.createSupplierPrices(this.idQuote);
          this.createComissions(this.idQuote, 'client', 'create');
          this.createComissions(this.idQuote, 'supplier', 'create');
          this.showToast('success', '¡Cotización guardada con éxito!');
          this.saveButtonStatus = false;
        } else {
        }
      }).catch(error => this.$verifyToken(error.response.data.data));
    },
    createClientPrices(idQuote){
      let clientPrices = [];  

      // Guardar clientPrices
      this.preciosClientes.forEach(element => {
        clientPrices.push({
          idQuote: idQuote,
          idCompany: element.idCompany,
          percentage: element.percentage,
          price: element.price,
          idItem: element.idItem,
          impact: element.impact,
          logisticPrice: element.logisticPrice,
          active: element.active,
          profit: element.profit,
          profitAction: element.profitAction,
          extraComissionPrice: element.extraComissionPrice,
        });
      });

      this.$axios.post(this.$services + 'clients/create_client_prices', {
        clientPrices: clientPrices,
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          // console.log(res.data.data)
        } else {
        }
      }).catch(error => this.$verifyToken(error.response.data.data));
    },
    createSupplierPrices(idQuote){
      let providerPrices = [];
      
      // Guardar proveedorPrecios
      this.preciosProveedores.forEach(element => {
        providerPrices.push({
          idQuote: idQuote,
          idCompany: element.idCompany,
          idItem: element.idItem,
          shipment1: element.shipment1,
          shipment2: element.shipment2,
          shipment3: null,
          adjustment1: element.adjustment1,
          adjustment2: element.adjustment2,
          selectedPrice: element.selectedPrice,
          idSelectedCompany: element.idSelectedCompany,
          logisticPrice: element.logisticPrice,
        });
      });

      this.$axios.post(this.$services + 'providers/create_provider_prices', {
        providerPrices: providerPrices,
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          // console.log(res.data.data)
        } else {
        }
      }).catch(error => this.$verifyToken(error.response.data.data));
    },
    updateClientPrices(idQuote){
      let clientPrices = [];  

      // Guardar clientPrices
      this.preciosClientes.forEach(element => {
        clientPrices.push({
          idQuote: idQuote,
          idCompany: element.idCompany,
          percentage: element.percentage,
          price: element.price,
          idItem: element.idItem,
          impact: element.impact,
          logisticPrice: element.logisticPrice,
          active: element.active,
          profit: element.profit,
          profitAction: element.profitAction,
          extraComissionPrice: element.extraComissionPrice,
        });
      });

      this.$axios.post(this.$services + 'clients/update_client_prices', {
        idQuote: String(idQuote),
        clientPrices: clientPrices,
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          // console.log(res.data.data)
        } else {
        }
      }).catch(error => this.$verifyToken(error.response.data.data));
    },
    updateSupplierPrices(idQuote){
      let providerPrices = [];
      
      // Guardar proveedorPrecios
      this.preciosProveedores.forEach(element => {
        providerPrices.push({
          idQuote: idQuote,
          idCompany: element.idCompany,
          idItem: element.idItem,
          shipment1: element.shipment1,
          shipment2: element.shipment2,
          shipment3: null,
          adjustment1: element.adjustment1,
          adjustment2: element.adjustment2,
          selectedPrice: element.selectedPrice,
          idSelectedCompany: element.idSelectedCompany,
          logisticPrice: element.logisticPrice,
        });
      });

      this.$axios.post(this.$services + 'providers/update_provider_prices', {
        idQuote: String(idQuote),
        providerPrices: providerPrices,
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          // console.log(res.data.data)
        } else {
        }
      }).catch(error => this.$verifyToken(error.response.data.data));
    },
    createComissions(idQuote, type, action) {

      let data;
      let url;
      let comissions = [];


      if (type == 'client') {
        data = this.preciosClientes;
      } else if (type == 'supplier') {
        data = this.preciosProveedores;
      }

      if (action == 'create') {
        url = 'commissions/create_company_commission';
      } else if (action == 'update') {
        url = 'commissions/update_company_commission';
      }


      data.forEach(company => {
        company.comissions.forEach(item => {
          let obj = {
            idCompany: company.idCompany,
            idQuote: idQuote,
            idICASC: item.idICASC,
            name: item.name,
            idItem: company.idItem,
            price: item.price,
          };
          comissions.push(obj);
        });
      });

      if (comissions.length > 0) { // Existe comissions
        this.$axios.post(this.$services + url, {
          idQuote: String(idQuote),
          comissions: comissions,
        }).then(res => {
          this.$refreshToken(res.headers['x-access-token']);
          if (!res.data.error) {
            // console.log(res.data.data)
          } else {
          }
        }).catch(error => this.$verifyToken(error.response.data.data));
      }
    },
    getPriceText(idCompany, idShipping, type) {
      let message = ""
      if (type == 'exp') {
        message = "PRECIOS EXPORTACION / CARGAS COMPLETAS\n";
        message += this.$moment().format("DD/MM/YYYY, HH:mm:ss") + "\n\n";

        if (idShipping == 1) {
          let arrayPrecios = this.preciosProveedores.filter(cotizacion => 
            cotizacion.idCompany === idCompany &&
            cotizacion.shipment1 > 0
          ); // Array de precios de ese proveedor con ese tipo de envio

          arrayPrecios.forEach(element => {
            message += element.itemName + " $" + element.shipment1 + " " + 
            utils.redondeo((element.shipment1/this.stockPrice['currencies'][0]['commodityPrice'])*1000) + " Dlls/Ton\n";
          });
        } else if (idShipping == 2){
          let arrayPrecios = this.preciosProveedores.filter(cotizacion => 
            cotizacion.idCompany === idCompany &&
            cotizacion.shipment2 > 0
          ); // Array de precios de ese proveedor con ese tipo de envio

          arrayPrecios.forEach(element => {
            message += element.itemName + " $" + element.shipment2 +"\n";
          });
        }
        if (message != "") {
          message += `\nNOTA: Estos precios solo son un indicativo \n
Todo material de exportación debe cerrarse BACK TO BACK \n
* Precio recogido occidente (gdl) - centro (df, edo mex) - noreste (mty) para otros puntos favor confirmar \n
Cargas completas, levantado, pago max 10 dias,CU 2 15-20 días \n
Cobre de 2a y delgado son precios entregados en Guadalajara \n
Con una ventana de tiempo de la llegada del material`;
        }

      } else {
        message = "";

        if (idShipping == 1) {
          let arrayPrecios = this.preciosProveedores.filter(cotizacion => 
            cotizacion.idCompany === idCompany &&
            cotizacion.shipment1 > 0
          ); // Array de precios de ese proveedor con ese tipo de envio

          arrayPrecios.forEach(element => {
            message += element.itemName + " $" + element.shipment1 +"\n";
          });
        } else if (idShipping == 2){
          let arrayPrecios = this.preciosProveedores.filter(cotizacion => 
            cotizacion.idCompany === idCompany &&
            cotizacion.shipment2 > 0
          ); // Array de precios de ese proveedor con ese tipo de envio

          arrayPrecios.forEach(element => {
            message += element.itemName + " $" + element.shipment2 +"\n";
          });
        }
        if (message != "") {
          message += "\n*Material planta Mty debe estar preparado/paca/jumbo/corto";
        }
      }

      console.log(message);

      return message;
    },
    copyText(idCompany, idShipping, type) {  // Copiar precios
      let texto = this.getPriceText(idCompany, idShipping, type);
      navigator.clipboard.writeText(texto);  // Guardar en portapapeles
    },
    messageSellers() {
      
      // Contenedores de parámetros
      let texts = [];
      let numbers = [];

      // Preparar información de cada proveedor
      let text;
      this.sellers.forEach(seller => {
        text = this.getPriceText(seller.idCompany, this.envios, 'exp');
        if (text === '') return;  // Si el texto es vacío, no hay que enviar SMS
        texts.push(text);
        numbers.push('+52' + String(seller.telephone));
      });

      this.sendSMS(texts, numbers);
    },
    sendSMS(texts, numbers) {
      this.smsStatus = true;

      // Mandar el mensaje
      this.$axios.post(this.$services + "messages/send_message", {
        texts: texts,
        numbers: numbers
        }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.showToast('success', String(res.data.data));
          this.smsSucess = true;
        }
      }).catch(error => {
        console.log(error);
        this.$verifyToken(error.response.data.data);
        this.showToast('danger', String('Error al enviar SMS'));
      }).finally(() => {
        this.smsStatus = false;
      });
    },
    updateValue(idCompany, idItem, type){ // Hace update del valor del input ($ o %)

      // Busca la informacion de ese usuario con ese metal
      let index = this.findUserItem(idCompany, idItem, 1);

      if (index > -1 ) { // La informacion existe
        if (type === 'price') { // Obtiene el precio con base al %
            return this.preciosClientes[index].price;
        } else if (type === 'percentage') { // Obtiene el % con base al $
            return this.preciosClientes[index].percentage;
        }
      } else { // No existe informacion
        return null;
      }
      
    },
    updateInput(idCompany, idItem, value, type, idShipping){ // Guarda el input y calcula el $ o %

        // Busca la informacion de ese usuario con ese metal
        let index = this.findUserItem(idCompany, idItem, 1);
        
        let stockValue = 0;
        let calculatedValue = 0; // $ o %

        let inputValue = parseFloat(value); // Parse al dato del input

        if (isNaN(inputValue)) { // Input es NaN
          inputValue = 0;
        }

        // Cliente START
        if (type === 'price') { // Actualizar precios y calcular porcentaje
          this.showButtons = false;
          calculatedValue = parseFloat(((inputValue * 100)/this.getStockPrice(stockValue, index)).toFixed(2)); // Obtener el porcentaje
          if (index > -1 ) { // Existe informacion
            this.preciosClientes[index].price = inputValue;
            this.preciosClientes[index].percentage = calculatedValue;
          }
        } else if (type === 'percentage') { // Actualizar porcentaje y calcular precios
          this.showButtons = false;
          calculatedValue = parseFloat(((inputValue * this.getStockPrice(stockValue, index))/100).toFixed(2)); // Obtener el precio
          if (index > -1 ) { // Existe informacion
            this.preciosClientes[index].price = calculatedValue;
            this.preciosClientes[index].percentage = inputValue;
          }
        } else if (type === 'extraComissionPrice') { // Actualizar la comision
          this.showButtons = false;
          if (index > -1 ) { // Existe informacion
            this.preciosClientes[index].extraComissionPrice = inputValue;
          }
        }
        // Cliente END

        // Proveedor START 
        else if (type === 'adjustment1') { // Actualizar el adjustment1
          // Obtiene array de usuarios con esos metales
          let arrayIndexShippingSupplier = this.findUserItem(idCompany, idItem, 2);
          if (arrayIndexShippingSupplier.length > 0 ) { // Existe array
            arrayIndexShippingSupplier.forEach(element => {
              // Para cada envio guarda el shipping fee
              this.preciosProveedores[element].adjustment1 = inputValue;  
            });
          } else {
            return null;
          }
        } else if (type === 'adjustment2') { // Actualizar el adjustment2
          // Obtiene array de usuarios con esos metales
          let arrayIndexShippingSupplier = this.findUserItem(idCompany, idItem, 2);
          if (arrayIndexShippingSupplier.length > 0 ) { // Existe array
            arrayIndexShippingSupplier.forEach(element => {
              // Para cada envio guarda el shipping fee
              this.preciosProveedores[element].adjustment2 = inputValue;  
            });
          } else {
            return null;
          }
        }
        // Proveedor END
    },
    getStockPrice(stockValue, index) { //Only for Clients
      const idStock = this.preciosClientes[index].idStock;
      const stockName = this.preciosClientes[index].stockName;
      const timeseries = this.preciosClientes[index].timeseries;

      // Buscar el commodity y su informacion
      const precioBolsa = this.stockPrice[stockName].filter(stock => stock.idStockName == idStock && stock.timeseries == timeseries);

      // Currency y measureUnit de la empresa
      let currencyCompany = this.preciosClientes[index].currency;
      let unitMeasurementCompany = this.preciosClientes[index].measurementUnit;

      // Currency, measureUnit y StockValue del stock
      let currencyStock = precioBolsa[0].currencyName;
      let unitMeasurementStock = precioBolsa[0].measurementName;
      stockValue = precioBolsa[0].commodityPrice;

      return this.conversionData(currencyStock, currencyCompany, unitMeasurementStock, unitMeasurementCompany, stockValue, timeseries);
    },
    conversionData(currencyOrigin, currencyTarget, unitMeasurementOrigin, unitMeasurementTarget, stockValue, timeseries) {
      let tipoCambio = null;

      // Si las divisas son diferentes
      if (currencyOrigin != currencyTarget) {

        if ((currencyOrigin == 'USD' && currencyTarget == 'MXN') ||
        (currencyOrigin == 'MXN' && currencyTarget == 'USD')) {
          // Precio de la divisa
          tipoCambio = this.stockPrice['currencies'].filter(currency => 
            currency.currencyNameOrigin == 'USD' &&
            currency.currencyNameTarget == 'MXN' && currency.timeseries == timeseries);

          // Convertir USD a MXN
          if (currencyOrigin == 'USD' && currencyTarget == 'MXN') {
            stockValue = tipoCambio[0].commodityPrice * stockValue;

          // Convertir MXN a USD
          } else if (currencyOrigin == 'MXN' && currencyTarget == 'USD') {
            stockValue = (1 / tipoCambio[0].commodityPrice) * stockValue;

          } 
        }
      }

      // Si las unidades son diferentes
      if (unitMeasurementOrigin != unitMeasurementTarget) {

        // Convertir TONS a KG
        if (unitMeasurementOrigin == 'TONS' && unitMeasurementTarget == 'KG') {
          stockValue /= 1000;
        } 
        // Convertir KG a TONS
        else if (unitMeasurementOrigin == 'KG' && unitMeasurementTarget == 'TONS') {
          stockValue *= 1000;
        }
        //Convertir LBS a KG
        else if (unitMeasurementOrigin == 'LB' && unitMeasurementTarget == 'KG') {
          stockValue /= 0.453;
        }
      }

      return stockValue;
    },
    findUserItem(idCompany, idItem, userType) { // Busca el Usuario-Metal
      let result;
      if (userType === 1) { // Busca el indice donde el company y metel sea el mismo
        result = this.preciosClientes.findIndex(company => 
          company.idCompany == idCompany && company.idItem == idItem
        );
      } else if (userType === 2) { // Busca los indices donde el proveedor y metal sean los mismos (diferentes envios)
        result = this.preciosProveedores.reduce(function(acc, curr, i) {
          if (curr.idCompany === idCompany && curr.idItem === idItem) {
            acc.push(i);
          }
          return acc;
        }, []);
      }

      return result;
    },
    findSupplierShippingItem(idCompany, idShipping, idItem) { // Busca el proveedor - envio - metal
      let result = this.preciosProveedores.findIndex(supplier => 
        supplier.idCompany == idCompany && 
        supplier.idItem == idItem && 
        supplier.idShipping == idShipping
      );
      return result;
    },
    confirmText() {
      if (this.idQuote == null) {
        this.$swal({
          title: '¿Estas seguro de crear una nueva cotización?',
          text: "Una vez creada la anterior ya no se podrá modificar",
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Crear',
          cancelButtonText: 'Voy a revisarlo',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.saveData();
            this.$swal({
              icon: 'success',
              title: '¡Bien!',
              text: 'Se ha creado una nueva cotización y la anterior se ha cerrado correctamente.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });
          }
        })
      } else {
        this.saveData();
      }
    },
    validatorText() {
      this.$swal({
        title: '¡Cuidado!',
        text: "No se han ingresado precios o se ha ingresado un resultado negativo",
        icon: 'error',
        confirmButtonText: 'Voy a revisarlo',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      });
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notificación',
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    restrictNumber(event){ // Solo numeros pueden ser ingresados
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      let stringValue = event.target.value.toString();
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || stringValue.indexOf('.') != -1)) { // 46 is dot
        event.preventDefault();
      }
    },
    // TODO CARLOS
    generatePrices() {

      let arrayMetales = [];
      let validator = true;

      //For metales
      this.preciosProveedores.forEach(element => {
        if (!arrayMetales.includes(element.idItem)) {
          arrayMetales.push(element.idItem);
        }
      });

      let metalsList = [];
      // Get metales por clientes
      arrayMetales.forEach(idItem => {

        // Obtiene arreglo de clientes con ese metal
        let data = this.preciosClientes.filter(item => 
          item.idItem == idItem &&
          item.price > 0 &&
          item.active
        );

        // Calcular precios finales
        let preciosCalculados = [];
        // console.log(data)
        data.forEach(element => {

          let comissions = element.comissions.reduce(function (acc, comission) { 
            return acc + comission.price; 
          }, 0);

          let profit = element.profit;

          if (element.profitAction === '%') {
            profit = element.price * (element.profit / 100);
          }

          let expenses = element.extraComissionPrice + profit + element.logisticPrice + comissions;

          let price = (element.price - expenses);

          // console.log(element.extraComissionPrice , profit , element.logisticPrice , comissions)
          // console.log(expenses)
          // console.log(price)

          // TIPO DE CAMBIO FALTA
          
          // Se agrupa todos los precios de ese item
          price = parseFloat(price.toFixed(2));

          let objData = {
            value: element.idCompany,
            idItem: element.idItem,
            text: element.companyName + ' ($' + price + ')',
            price: price,
          };

          // Validador que al menos un precio es positivo
          if (price <= 0) {
            validator = true;
          } else {
            validator = false;
          }
          preciosCalculados.push(objData);
        });

        // Sort DESC arreglo de precios
        preciosCalculados = preciosCalculados.sort(function(a, b) {
          return b.price - a.price;
        });
        metalsList[idItem] = preciosCalculados;
      });
      
      // Busca por cada item de proveedor el precio mas alto y lo setea
      this.preciosProveedores.forEach(providerData => {
        let index = this.findUserItem(providerData.idCompany, providerData.idItem, 2);
        
        // Not null
        if (metalsList[providerData.idItem][0]) {
          //Set precios mas altos
          this.preciosProveedores[index].idSelectedCompany = metalsList[providerData.idItem][0].value;

          this.preciosProveedores[index].selectedPrice = metalsList[providerData.idItem][0].price;
        } else {
          this.preciosProveedores[index].idSelectedCompany = null;

          this.preciosProveedores[index].selectedPrice = null; 
        }

      });


      // Set valores globales
      this.metalsList = [];
      this.metalsList = metalsList;

      if (!validator) {
        this.showButtons = true;
      } else {
        this.validatorText();
      }

    },
    // TODO CARLOS
    getSelectedPrices(idCompany, idItem) {
      let index = this.findUserItem(idCompany, idItem, 2);
      return this.preciosProveedores[index].idSelectedCompany;
    },
    // TODO CARLOS
    newSelectedPrices(idCompanyClient, idItem, idCompanySupplier) {
      let index = this.findUserItem(idCompanySupplier, idItem, 2);
      
      // Buscar info del cliente
      let value = this.metalsList[idItem].filter(item =>
      item.value == idCompanyClient);

      // Set data
      this.preciosProveedores[index].idSelectedCompany = idCompanyClient;
      this.preciosProveedores[index].selectedPrice = value[0].price;
      this.preciosProveedores[0].random = Math.random();
    },
    // TODO CARLOS
    getBuyingPrices(idCompany, idShipping, idItem) {
      let index = this.findUserItem(idCompany, idItem, 2);

      let price = this.preciosProveedores[index].selectedPrice;
      let comissions = this.preciosProveedores[index].comissions.reduce(function (acc, comission) { 
        return acc + comission.price; 
      }, 0);

      // Obtiene comisiones de proveedor y envio por proveedor
      let adjustment1 = this.preciosProveedores[index].adjustment1;
      let adjustment2 = this.preciosProveedores[index].adjustment2;
      let logisticPrice = this.preciosProveedores[index].logisticPrice;
      // Trigger
      let random = this.preciosProveedores[index].random;

      // Calcula el precio - comisiones por cada proveedor por cada tipo de envio
      let expenses = comissions + adjustment1;
      // console.log(idShipping)
      if (idShipping == 1) {
        // console.log(price , expenses , adjustment2)
        price = price - expenses + adjustment2 + (random*0);
        price = parseFloat((price).toFixed(2)); // Formato de precio
        if (price > 0) {
          this.preciosProveedores[index].shipment1 = price;
        }
      } else if (idShipping == 2) {
        //  TODO Revisar si se suma o resta
        price = price - (price * 0.0035) - logisticPrice - expenses + adjustment2;
        price = parseFloat((price).toFixed(2)); // Formato de precio
        // console.log(price , (price * 0.0035) , logisticPrice , expenses , adjustment2)
        if (price > 0) {
          this.preciosProveedores[index].shipment2 = price;
        }
      }

      if (price > 0) {
        return price;
      } else {
        return null;
      }
    },
    // TODO CARLOS
    getComissionsPrices(idCompany, idItem, adjustment) {
      let index = this.findUserItem(idCompany, idItem, 2);

      if (adjustment == 1) {
        return this.preciosProveedores[index].adjustment1;
      } else if (adjustment == 2) {
        return this.preciosProveedores[index].adjustment2;
      }
    },
    // Comparar precio anterior y actual para elegir ícono de cambio
    getTrendIcon(idCompany, idItem, type) {

      let index = this.pastPreciosClientes.findIndex(company => 
        company.idCompany == idCompany &&
        company.idItem == idItem
      );

      if (index > -1) {
        // Declarar precio anterior y nuevo
        let pastPrice = this.pastPreciosClientes[index].price;
        let newPrice = this.preciosClientes[index].price;

        // Comparar y return si es mayor o menor
        if (type === 'icon') {
          if (pastPrice < newPrice && newPrice > 0) {
            return 'TrendingUpIcon';
          } else if (pastPrice > newPrice && newPrice > 0) {
            return 'TrendingDownIcon';
          } else  {
            return 'MinusIcon';
          }
        } else if (type === 'color') {
          if (pastPrice < newPrice && newPrice > 0) {
            return 'text-success';
          } else if (pastPrice > newPrice && newPrice > 0) {
            return 'text-danger';
          } else {
            return '';
          }
        }
      }

      // Default
      if (type === 'icon') {
        return 'MinusIcon';
      } else if (type === 'color') {
        return ''
      }
    },
  },
  computed: {
  },
}
</script>
